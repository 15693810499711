import * as React from "react";

const Logo = () => (
  <svg viewBox="0 0 418.13 41.734">
    <g transform="translate(-118.911 -54.089)">
      <g>
        <path
          d="M138.075,79.969a6.414,6.414,0,0,1-2.568,5.409q-2.569,1.95-7.508,1.95a20.9,20.9,0,0,1-3.679-.3,11.533,11.533,0,0,1-2.865-.864,5.259,5.259,0,0,1-1.877-1.383,2.784,2.784,0,0,1-.667-1.8,3.083,3.083,0,0,1,.42-1.63,3.477,3.477,0,0,1,1.112-1.136,17.285,17.285,0,0,0,3.185,1.58,11.59,11.59,0,0,0,4.174.692,5.71,5.71,0,0,0,3.062-.667,2.09,2.09,0,0,0,1.037-1.852q0-2.025-2.963-2.667l-2.766-.642a11.411,11.411,0,0,1-5.408-2.519,5.927,5.927,0,0,1-1.754-4.494,6.3,6.3,0,0,1,2.569-5.211q2.566-2,7.211-2a18.648,18.648,0,0,1,3.284.271,10.708,10.708,0,0,1,2.618.791,5.055,5.055,0,0,1,1.728,1.235,2.456,2.456,0,0,1,.618,1.654,2.993,2.993,0,0,1-.395,1.555A3.5,3.5,0,0,1,135.7,69a6.788,6.788,0,0,0-1.16-.593,14.765,14.765,0,0,0-1.581-.543,18.735,18.735,0,0,0-1.827-.42,10.781,10.781,0,0,0-1.9-.173,6.142,6.142,0,0,0-3.013.617,1.928,1.928,0,0,0-1.086,1.754,1.755,1.755,0,0,0,.617,1.382,4.994,4.994,0,0,0,2.149.89l2.42.592a12.284,12.284,0,0,1,5.926,2.742A6.333,6.333,0,0,1,138.075,79.969Z"
          fill="#ffcd00"
        />
        <path
          d="M148.347,86.785a8.6,8.6,0,0,1-1.679.148,4.21,4.21,0,0,1-2.568-.642,2.7,2.7,0,0,1-.84-2.272V54.484a6.436,6.436,0,0,1,1.161-.272,10.785,10.785,0,0,1,1.7-.123,4.214,4.214,0,0,1,2.568.642,2.7,2.7,0,0,1,.84,2.272v6.815a14.216,14.216,0,0,1,2.494-.938,11.6,11.6,0,0,1,3.136-.4,10.531,10.531,0,0,1,7.162,2.321q2.667,2.324,2.667,7.014V86.538q-.4.1-1.161.247a8.7,8.7,0,0,1-1.654.148,4.311,4.311,0,0,1-2.618-.642,2.7,2.7,0,0,1-.84-2.272v-11.9a4.5,4.5,0,0,0-1.284-3.581,4.952,4.952,0,0,0-3.358-1.111,7.092,7.092,0,0,0-2.47.42,11.007,11.007,0,0,0-2.074,1.013V86.538Q149.087,86.637,148.347,86.785Z"
          fill="#ffcd00"
        />
        <path
          d="M180.4,62.485a12.983,12.983,0,0,1,7.729,2.075q2.891,2.073,2.89,6.42V82.389a2.788,2.788,0,0,1-.593,1.877,6.125,6.125,0,0,1-1.482,1.235,12.023,12.023,0,0,1-3.506,1.334,21.548,21.548,0,0,1-4.841.493,13.838,13.838,0,0,1-7.778-1.9,6.405,6.405,0,0,1-2.84-5.705,6.222,6.222,0,0,1,2.1-5.062,11.059,11.059,0,0,1,6.1-2.149l6.767-.691v-.889a3.071,3.071,0,0,0-1.358-2.766,6.966,6.966,0,0,0-3.828-.889,15.072,15.072,0,0,0-3.754.469,17.617,17.617,0,0,0-3.26,1.161,3.211,3.211,0,0,1-.913-1.062,2.948,2.948,0,0,1-.371-1.457,2.582,2.582,0,0,1,.469-1.605,4.019,4.019,0,0,1,1.507-1.111,14.615,14.615,0,0,1,3.26-.89A23.146,23.146,0,0,1,180.4,62.485Zm.2,20.1a11.676,11.676,0,0,0,2.618-.272,4.89,4.89,0,0,0,1.729-.666V76.166l-4.791.444a6.92,6.92,0,0,0-3.013.865,2.315,2.315,0,0,0-1.037,2.1,2.6,2.6,0,0,0,1.062,2.222A5.819,5.819,0,0,0,180.6,82.587Z"
          fill="#ffcd00"
        />
        <path
          d="M208.355,87.279a12.06,12.06,0,0,1-2.642-.272,14.337,14.337,0,0,1-2.2-.667v9.088q-.445.1-1.185.247a8.606,8.606,0,0,1-1.68.148,4.21,4.21,0,0,1-2.568-.642,2.7,2.7,0,0,1-.84-2.272V67.869a3.541,3.541,0,0,1,.47-1.927,4.582,4.582,0,0,1,1.506-1.382,15.768,15.768,0,0,1,3.556-1.457,17.14,17.14,0,0,1,4.791-.618,16.786,16.786,0,0,1,5.013.716,10.606,10.606,0,0,1,3.976,2.223,10.175,10.175,0,0,1,2.618,3.828,14.74,14.74,0,0,1,.938,5.531,15.144,15.144,0,0,1-.889,5.433,11.169,11.169,0,0,1-2.445,3.9,9.926,9.926,0,0,1-3.7,2.371A13.589,13.589,0,0,1,208.355,87.279Zm-.938-4.988a5.987,5.987,0,0,0,4.642-1.828q1.678-1.827,1.68-5.68,0-4-1.729-5.679a6.114,6.114,0,0,0-4.445-1.68,7.266,7.266,0,0,0-2.346.346,12.071,12.071,0,0,0-1.754.741V81.2a7.194,7.194,0,0,0,3.952,1.087Z"
          fill="#ffcd00"
        />
        <path
          d="M230.383,77.7a5.854,5.854,0,0,0,2.568,3.655,8.812,8.812,0,0,0,4.643,1.136,11.447,11.447,0,0,0,3.828-.617,13.955,13.955,0,0,0,2.839-1.309,3.262,3.262,0,0,1,1.136,1.087,2.686,2.686,0,0,1,.445,1.481,2.587,2.587,0,0,1-.667,1.753,5.4,5.4,0,0,1-1.827,1.309,11.4,11.4,0,0,1-2.741.84,18.483,18.483,0,0,1-3.408.3,16.87,16.87,0,0,1-5.31-.79,10.825,10.825,0,0,1-4.1-2.4,11.128,11.128,0,0,1-2.667-3.976,14.629,14.629,0,0,1-.963-5.532A13.905,13.905,0,0,1,225.1,69.3a10.935,10.935,0,0,1,2.544-3.8,10.211,10.211,0,0,1,3.729-2.272,13.54,13.54,0,0,1,4.494-.741,12.463,12.463,0,0,1,4.445.766,10.194,10.194,0,0,1,3.458,2.124,9.7,9.7,0,0,1,2.247,3.259,10.363,10.363,0,0,1,.815,4.124,2.761,2.761,0,0,1-.716,2.075,3.587,3.587,0,0,1-2,.889Zm5.482-10.52a5.547,5.547,0,0,0-4.05,1.6,6.8,6.8,0,0,0-1.827,4.569l10.964-1.63a5.512,5.512,0,0,0-1.432-3.186A4.694,4.694,0,0,0,235.865,67.177Z"
          fill="#ffcd00"
        />
      </g>
      <g>
        <path
          d="M293.776,77.5q1.334,6.471,8.495,6.47a9.758,9.758,0,0,0,3.754-.692,10.966,10.966,0,0,0,2.667-1.482,2.1,2.1,0,0,1,1.185,1.877,1.921,1.921,0,0,1-.617,1.334,5.559,5.559,0,0,1-1.679,1.136,11.473,11.473,0,0,1-2.47.79,14.7,14.7,0,0,1-3.037.3q-5.73,0-9.064-3.112t-3.333-9.088a14.343,14.343,0,0,1,.815-5.013,10.744,10.744,0,0,1,2.272-3.729,9.77,9.77,0,0,1,3.482-2.346,11.665,11.665,0,0,1,4.395-.815,11.071,11.071,0,0,1,4.1.741,9.406,9.406,0,0,1,3.235,2.1,9.757,9.757,0,0,1,2.123,3.21,10.56,10.56,0,0,1,.766,4.075,2.075,2.075,0,0,1-.494,1.556,2.316,2.316,0,0,1-1.383.568Zm6.816-11.162a6.56,6.56,0,0,0-4.989,2.1,8.77,8.77,0,0,0-2.074,6l13.582-1.877a7.258,7.258,0,0,0-1.9-4.47A6.044,6.044,0,0,0,300.592,66.338Z"
          fill="#fff"
        />
        <path
          d="M343.907,67.523a5.659,5.659,0,0,0-3.7-1.136,8.323,8.323,0,0,0-5.73,2.272,7.343,7.343,0,0,1,.3,2.124V86.489a5.89,5.89,0,0,1-.766.222,4.59,4.59,0,0,1-1.062.124q-2.124,0-2.123-1.779V71.079a4.342,4.342,0,0,0-1.383-3.556,5.744,5.744,0,0,0-3.754-1.136,8.963,8.963,0,0,0-2.988.494,11.24,11.24,0,0,0-2.4,1.136V86.489a6.168,6.168,0,0,1-.74.222,4.5,4.5,0,0,1-1.087.124q-2.124,0-2.124-1.779V68.214a3.048,3.048,0,0,1,.42-1.654,4.9,4.9,0,0,1,1.457-1.358,13.91,13.91,0,0,1,3.383-1.457,14.215,14.215,0,0,1,4.124-.618,12.007,12.007,0,0,1,4.3.716,6.575,6.575,0,0,1,2.865,1.951,10.829,10.829,0,0,1,3.21-1.9,11.814,11.814,0,0,1,4.4-.766,9.889,9.889,0,0,1,6.371,1.9q2.371,1.9,2.371,5.852V86.489a5.915,5.915,0,0,1-.765.222,4.59,4.59,0,0,1-1.062.124q-2.124,0-2.124-1.779V71.079A4.342,4.342,0,0,0,343.907,67.523Z"
          fill="#fff"
        />
        <path
          d="M366.577,63.127a12,12,0,0,1,4.247.741,9.382,9.382,0,0,1,3.433,2.222,10.156,10.156,0,0,1,2.272,3.754,15.808,15.808,0,0,1,.815,5.334,15.527,15.527,0,0,1-.84,5.384,10.071,10.071,0,0,1-2.346,3.754,9.36,9.36,0,0,1-3.63,2.2,14.461,14.461,0,0,1-4.643.717,15.65,15.65,0,0,1-4.371-.568,13.654,13.654,0,0,1-3.185-1.309,4.45,4.45,0,0,1-1.433-1.26,3.031,3.031,0,0,1-.395-1.654V54.583a6.021,6.021,0,0,1,.766-.223,4.8,4.8,0,0,1,1.111-.123q2.074,0,2.074,1.778v8.841a12.217,12.217,0,0,1,2.593-1.21A10.849,10.849,0,0,1,366.577,63.127Zm-.593,3.26a7.716,7.716,0,0,0-3.136.618,9.672,9.672,0,0,0-2.4,1.457V82.439a8.424,8.424,0,0,0,2.149,1.037,9.921,9.921,0,0,0,3.284.494,8.46,8.46,0,0,0,3.038-.519,6.037,6.037,0,0,0,2.346-1.6,7.488,7.488,0,0,0,1.531-2.741,12.643,12.643,0,0,0,.543-3.927,12.885,12.885,0,0,0-.543-3.951,7.242,7.242,0,0,0-1.531-2.741,6.178,6.178,0,0,0-2.346-1.581A8.192,8.192,0,0,0,365.984,66.387Z"
          fill="#fff"
        />
        <path
          d="M386.086,77.5q1.334,6.471,8.495,6.47a9.75,9.75,0,0,0,3.753-.692A11,11,0,0,0,401,81.8a2.1,2.1,0,0,1,1.185,1.877,1.921,1.921,0,0,1-.617,1.334,5.565,5.565,0,0,1-1.68,1.136,11.446,11.446,0,0,1-2.469.79,14.713,14.713,0,0,1-3.038.3q-5.73,0-9.063-3.112t-3.334-9.088a14.366,14.366,0,0,1,.815-5.013,10.759,10.759,0,0,1,2.272-3.729,9.788,9.788,0,0,1,3.482-2.346,11.67,11.67,0,0,1,4.4-.815,11.062,11.062,0,0,1,4.1.741,9.406,9.406,0,0,1,3.235,2.1,9.759,9.759,0,0,1,2.124,3.21,10.578,10.578,0,0,1,.766,4.075,2.075,2.075,0,0,1-.494,1.556,2.321,2.321,0,0,1-1.383.568ZM392.9,66.338a6.559,6.559,0,0,0-4.988,2.1,8.766,8.766,0,0,0-2.074,6l13.582-1.877a7.259,7.259,0,0,0-1.9-4.47A6.041,6.041,0,0,0,392.9,66.338Z"
          fill="#fff"
        />
        <path
          d="M428.166,82.834a2.452,2.452,0,0,1-.42,1.531,4.81,4.81,0,0,1-1.309,1.087,11.655,11.655,0,0,1-2.815,1.21,14.18,14.18,0,0,1-4.2.568,16.4,16.4,0,0,1-4.89-.692,10.05,10.05,0,0,1-3.827-2.148,9.5,9.5,0,0,1-2.47-3.705,15.082,15.082,0,0,1-.864-5.408,15.257,15.257,0,0,1,.889-5.507,10.533,10.533,0,0,1,2.4-3.778,9.193,9.193,0,0,1,3.556-2.174,13.607,13.607,0,0,1,4.371-.691,11.843,11.843,0,0,1,3.062.4,11.661,11.661,0,0,1,2.569.988V54.583a6,6,0,0,1,.765-.223,4.583,4.583,0,0,1,1.062-.123q2.124,0,2.124,1.778Zm-3.951-14.768a10.633,10.633,0,0,0-2.272-1.185,8.236,8.236,0,0,0-2.964-.494,8.8,8.8,0,0,0-2.889.469,6.109,6.109,0,0,0-2.395,1.531,7.562,7.562,0,0,0-1.655,2.791,12.54,12.54,0,0,0-.617,4.2q0,4.545,2.173,6.569a8.131,8.131,0,0,0,5.779,2.025,9.128,9.128,0,0,0,2.839-.371,9.681,9.681,0,0,0,2-.913Z"
          fill="#fff"
        />
        <path
          d="M454.935,82.834a2.452,2.452,0,0,1-.42,1.531,4.82,4.82,0,0,1-1.308,1.087,11.664,11.664,0,0,1-2.816,1.21,14.171,14.171,0,0,1-4.2.568,16.391,16.391,0,0,1-4.889-.692,10.046,10.046,0,0,1-3.828-2.148,9.514,9.514,0,0,1-2.47-3.705,15.081,15.081,0,0,1-.864-5.408,15.257,15.257,0,0,1,.889-5.507,10.548,10.548,0,0,1,2.4-3.778,9.186,9.186,0,0,1,3.556-2.174,13.607,13.607,0,0,1,4.371-.691,11.854,11.854,0,0,1,3.062.4,11.652,11.652,0,0,1,2.568.988V54.583a5.972,5.972,0,0,1,.766-.223,4.577,4.577,0,0,1,1.062-.123q2.122,0,2.123,1.778Zm-3.951-14.768a10.633,10.633,0,0,0-2.272-1.185,8.226,8.226,0,0,0-2.963-.494,8.813,8.813,0,0,0-2.89.469,6.11,6.11,0,0,0-2.4,1.531,7.59,7.59,0,0,0-1.655,2.791,12.54,12.54,0,0,0-.617,4.2q0,4.545,2.173,6.569a8.133,8.133,0,0,0,5.779,2.025,9.139,9.139,0,0,0,2.84-.371,9.672,9.672,0,0,0,2-.913Z"
          fill="#fff"
        />
        <path
          d="M465.011,77.5q1.334,6.471,8.5,6.47a9.758,9.758,0,0,0,3.754-.692,10.967,10.967,0,0,0,2.667-1.482,2.1,2.1,0,0,1,1.185,1.877,1.921,1.921,0,0,1-.617,1.334,5.552,5.552,0,0,1-1.68,1.136,11.446,11.446,0,0,1-2.469.79,14.713,14.713,0,0,1-3.038.3q-5.73,0-9.063-3.112t-3.334-9.088a14.366,14.366,0,0,1,.815-5.013A10.774,10.774,0,0,1,464,66.288a9.788,9.788,0,0,1,3.482-2.346,11.674,11.674,0,0,1,4.4-.815,11.062,11.062,0,0,1,4.1.741,9.406,9.406,0,0,1,3.235,2.1,9.76,9.76,0,0,1,2.124,3.21,10.579,10.579,0,0,1,.766,4.075,2.075,2.075,0,0,1-.494,1.556,2.318,2.318,0,0,1-1.383.568Zm6.816-11.162a6.559,6.559,0,0,0-4.989,2.1,8.765,8.765,0,0,0-2.074,6l13.582-1.877a7.264,7.264,0,0,0-1.9-4.47A6.044,6.044,0,0,0,471.827,66.338Z"
          fill="#fff"
        />
        <path
          d="M507.091,82.834a2.452,2.452,0,0,1-.42,1.531,4.81,4.81,0,0,1-1.309,1.087,11.654,11.654,0,0,1-2.815,1.21,14.175,14.175,0,0,1-4.2.568,16.4,16.4,0,0,1-4.89-.692,10.05,10.05,0,0,1-3.827-2.148,9.5,9.5,0,0,1-2.47-3.705,15.082,15.082,0,0,1-.864-5.408,15.257,15.257,0,0,1,.889-5.507,10.546,10.546,0,0,1,2.395-3.778,9.2,9.2,0,0,1,3.556-2.174,13.607,13.607,0,0,1,4.371-.691,11.856,11.856,0,0,1,3.063.4,11.672,11.672,0,0,1,2.568.988V54.583a6,6,0,0,1,.765-.223,4.59,4.59,0,0,1,1.062-.123q2.124,0,2.124,1.778ZM503.14,68.066a10.634,10.634,0,0,0-2.272-1.185,8.236,8.236,0,0,0-2.964-.494,8.8,8.8,0,0,0-2.889.469,6.11,6.11,0,0,0-2.4,1.531,7.576,7.576,0,0,0-1.655,2.791,12.54,12.54,0,0,0-.617,4.2q0,4.545,2.173,6.569A8.131,8.131,0,0,0,498.3,83.97a9.14,9.14,0,0,0,2.84-.371,9.7,9.7,0,0,0,2-.913Z"
          fill="#fff"
        />
      </g>
      <path
        d="M519.394,67.3l16.368,6.866L519.394,81.03"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.557"
      />
      <path
        d="M279.285,81.03l-16.368-6.866L279.285,67.3"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.557"
      />
    </g>
  </svg>
);

export default Logo;
