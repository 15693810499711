import * as React from "react";

const ArrowIntoSquare = () => (
  <svg width="87.363" height="66.783" viewBox="0 0 87.363 66.783">
    <g transform="translate(-818.787 -3489.183)">
      <path
        d="M840.366,3506.291v-16.108H905.15v64.783H840.366v-16.54"
        fill="none"
        stroke="#1d1d1b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />

      <path
        d="M840.366,3513.448l22.577,9.47-22.577,9.47"
        fill="none"
        stroke="#1d1d1b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <line
        x2="41.481"
        transform="translate(819.787 3522.917)"
        fill="none"
        stroke="#1d1d1b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
  </svg>
);
export default ArrowIntoSquare;
