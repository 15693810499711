import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  html {
    font-size: 100%;
  }
  body {
    margin: 0;
    padding: 0;
    background: #000;
    font-family: "Lato", sans-serif;
  }
  h1, h2, h3 {
    font-family: "Baloo 2", cursive;
    font-weight: 500;
  }
`;

export default GlobalStyles;
