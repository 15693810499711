import * as React from "react";
import styled from "styled-components";

import {
  container,
  darkSection,
  pointyAfter,
  sectionPadding,
} from "../style/mixins";
import media from "../style/media";

const Section = styled.section`
  ${darkSection};
  ${sectionPadding};
`;

const Container = styled.div`
  ${container};

  h2 {
    font-size: 2.5rem;
    line-height: 1.2;
    margin: 0 0 3rem 0;
    text-align: left ${pointyAfter};

    ${media.desktop`
      font-size: 3rem;
      margin: 0 0 5rem 0;
      text-align: center;
    `}
  }

  figure {
    margin: 0 0 2rem 0;
    text-align: left;

    ${media.desktop`
      margin: 0 0 4rem 0;
      text-align: center;
    `}

    blockquote {
      margin: 0 0 0 0;
      font-size: 1.5rem;
      line-height: 1.5;

      border-left: 0.25rem solid;
      padding-left: 2rem;

      ${media.tablet`
        margin: 0 0 0 2rem;
      `}

      ${media.desktop`
        margin: 0;
        font-size: 1.75rem;
        border-left: none;
      `}
    }

    figcaption {
      margin: 0 0 0 2.25rem;
      ${media.tablet`
        margin: 0 0 0 2rem;
      `}
      ${media.desktop`
        margin: 0;        
      `}

      span {
        color: #ffcd00;
        display: block;

        font-size: 1.5rem;
        line-height: 1.5;

        ${media.desktop`
          display: block;
          font-size: 1.75rem;
        `}
      }

      span:first-of-type {
        font-family: "Baloo 2", cursive;
        font-weight: 500;
      }

      span:last-of-type {
        font-size: 1.25rem;
      }
    }
  }
`;

const TestimonialSection = () => (
  <Section>
    <Container>
      <h2>Here’s what our customers say about us</h2>
      <figure>
        <blockquote>
          <p>
            We’ve always seen payroll as a natural extension of our product —
            and our customers did too. That’s why we investigated payroll APIs
            and Shape was by far the best technology out there to help us create
            the all-in-one solution our customers wanted.{" "}
          </p>
        </blockquote>
        <figcaption>
          <span>Rupert Lassen</span>
          <span>Managing Director, Chronicle Computing</span>
        </figcaption>
      </figure>
    </Container>
  </Section>
);

export default TestimonialSection;
