import * as React from "react";

import Dialog from "@mui/material/Dialog";

import LinkButton from "./LinkButton";
import ContactForm from "./ContactForm";

const ContactButton = ({ children, ...rest }) => {
  const [isOpen, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <LinkButton
        href="#"
        {...rest}
        onClick={() => {
          setOpen(true);
        }}
      >
        {children}
      </LinkButton>
      <Dialog open={isOpen}>
        <ContactForm onComplete={handleClose} onCancel={handleClose} />
      </Dialog>
    </>
  );
};

export default ContactButton;
