import * as React from "react";

const Arrow = ({ stroke, strokeWidth = "81.426" }) => (
  <svg width="100%" height="100%" viewBox="0 0 615.144 543.773">
    <path
      d="M1209.941,403.52,1731.08,622.115,1209.941,840.71"
      transform="translate(-1156.649 -350.228)"
      fill="none"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
    />
  </svg>
);

export default Arrow;
