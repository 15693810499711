import * as React from "react";

import Header from "../components/Header";
import GlobalStyles from "../style/GlobalStyles";
import Hero from "../sections/Hero";
import ProductSection from "../sections/Product";
import CustomerSection from "../sections/Customer";
import FeaturesSection from "../sections/Features";
import TestimonialSection from "../sections/Testimonial";
import AlecSection from "../sections/Alec";
import Footer from "../components/Footer";

const IndexPage = () => {
  return (
    <>
      <GlobalStyles />
      <Header />

      <main>
        <Hero />
        <ProductSection />
        <CustomerSection />
        <FeaturesSection />
        <TestimonialSection />
        <AlecSection />
      </main>
      <Footer />
    </>
  );
};

export default IndexPage;

export const Head = () => <title>Shape Embedded</title>;
