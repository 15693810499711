import * as React from "react";
import styled from "styled-components";

import Logo from "./Logo";
import ContactButton from "./ContactButton";
import media from "../style/media";

const Container = styled.div`
  background: #1d1d1b;

  padding: 2rem;
  text-align: center;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ${media.tablet`
    text-align: left;
    padding: 3.125rem 2rem;
  `}

  ${media.desktop`    
    padding: 3.125rem 7.5rem;
  `}
`;

const LogoHolder = styled.div`
  flex: 1;

  ${media.tablet`
    max-width: 20rem;
  `}

  ${media.desktop`
    max-width: 30rem;
  `}
`;

const Links = styled.div`
  display: none;

  ${media.tablet`
    display: block;
    text-align: left;
  `}
`;

const Header = () => (
  <Container>
    <header>
      <LogoHolder>
        <Logo />
      </LogoHolder>
      <Links>
        <ContactButton $primary>contact us</ContactButton>
      </Links>
    </header>
  </Container>
);

export default Header;
