import * as React from "react";

const Profit = ({ stroke }) => (
  <svg viewBox="0 0 66.843 66.843">
    <g transform="translate(-1209.834 -3489.153)">
      <path
        d="M1275.677,3555h-64.843v-64.843"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        d="M1216.669,3546.879l10.417-10.417h8.694l6.492-6.492H1253.3l13.271-21.843"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
  </svg>
);

export default Profit;
