import * as React from "react";
import styled from "styled-components";

import { container, greySection, sectionPadding } from "../style/mixins";
import media from "../style/media";
import Stack from "../icons/Stack";
import Clock from "../icons/Clock";
import Profit from "../icons/Profit";
import ArrowIntoSquare from "../icons/ArrowIntoSquare";
import ContactButton from "../components/ContactButton";
import Customer from "../icons/Customer";
import Handshake from "../icons/Handshake";

const Section = styled.section`
  ${greySection};
  ${sectionPadding};
`;

const Container = styled.div`
  ${container};

  h2 {
    font-size: 2rem;
    margin: 0 0 2rem 0;
    text-align: left;

    ${media.desktop`
      font-size: 3rem;
      margin: 0 0 5rem 0;
      text-align: center;
    `}
  }
`;

const Lead = styled.p`
  font-size: 1.6rem;
  line-height: 1.35;
  margin: 0 0 2rem 0;
  text-align: left;

  ${media.desktop`
      font-size: 2rem;
      margin: 0 0 5rem 0;
      text-align: center;
    `}
`;

const FeatureHolder = styled.div`
  margin: 0 0 1rem 0;

  ${media.tablet`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem 1rem -1rem;
   `}
`;

const Feature = styled.div`
  background: #ffcd00;
  color: #1d1d1b;
  border-radius: 1.625rem;

  margin: 1rem 0;
  padding: 2rem;

  flex: 25%;

  p {
    font-size: 1.5rem;
    line-height: 1.5;

    ${media.desktop`
      font-size: 1.75rem;
    `}
  }

  ${media.tablet`
      margin: 1rem;
    `}
`;

const Icon = styled.div`
  width: 4.125rem;
`;

const CtaHolder = styled.div`
  margin: 6rem 0 4rem 0;
  display: flex;
  justify-content: center;
`;

const FeaturesSection = () => (
  <Section>
    <Container>
      <h2>Super-fast payroll, built to scale</h2>
      <Lead>
        Our robust API gives you industry-leading tools for a first-class
        developer experience, combined with world-class support to help you get
        the most from the Shape platform as and when you need it.
      </Lead>
      <FeatureHolder>
        <Feature>
          <Icon>
            <Profit stroke="#1d1d1b" />
          </Icon>
          <p>Increase revenue quickly</p>
        </Feature>

        <Feature>
          <Icon>
            <Customer stroke="#1d1d1b" />
          </Icon>
          <p>Enhance customer experience</p>
        </Feature>
        <Feature>
          <Icon>
            <Clock stroke="#1d1d1b" />
          </Icon>
          <p>Launch and maintain at very low cost</p>
        </Feature>

        <Feature>
          <Icon>
            <ArrowIntoSquare stroke="#1d1d1b" />
          </Icon>
          <p>Tried and tested at scale</p>
        </Feature>
        <Feature>
          <Icon>
            <Stack stroke="#1d1d1b" height="66.843" />
          </Icon>
          <p>Understand your customers more</p>
        </Feature>
        <Feature>
          <Icon>
            <Handshake stroke="#1d1d1b" />
          </Icon>
          <p>Partner with the market-leader</p>
        </Feature>
      </FeatureHolder>
      <CtaHolder>
        <ContactButton $secondary>get started</ContactButton>
      </CtaHolder>
    </Container>
  </Section>
);

export default FeaturesSection;
