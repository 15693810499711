import * as React from "react";
import styled, { css } from "styled-components";

import { blackSection, sectionPadding, container } from "../style/mixins";
import media from "../style/media";
import Box from "../icons/Box";
import Arrow from "../components/Arrow";
import Clock from "../icons/Clock";
import IdCard from "../icons/IdCard";
import Bank from "../icons/Bank";
import Profit from "../icons/Profit";

const Section = styled.section`
  ${blackSection}
  ${sectionPadding};

  overflow-x: hidden;
`;

const Container = styled.div`
  ${container};
  position: relative;

  h2 {
    font-size: 2rem;
    line-height: 1.4;
    margin: 0 0 2rem 0;
    text-align: center;

    span {
      font-weight: 400;
    }

    ${media.desktop`
      font-size: 2.2rem;
      margin: 0 0 5rem 0;
    `}
  }
`;

const CustomerHolder = styled.div``;

const Customer = styled.div`
  margin: 2rem 0;

  border: 3px solid #ffffff;
  border-radius: 1.18rem;
  padding: 2rem 1rem;

  ${media.desktop`
    margin: 4.5rem 0;
    padding; 0;
    
    border: none;
    
    display: flex;
    align-items: center;
  `}
`;

const Icon = styled.div`
  width: 4rem;
  height: 4rem;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 4rem;
  }

  ${media.desktop`
    width: 16rem;
    height: 16rem;
    flex-shrink: 0;
    
    border: 3px solid #ffffff;
    border-radius: 1.18rem;
    
    svg {
      width: 8rem
    }
  `}
`;

const Copy = styled.div`
  h3 {
    color: #ffcd00;
    span {
      font-family: "Lato", sans-serif;
      font-weight: 300;
      text-transform: uppercase;
      font-size: 1.3rem;
    }
    div {
      font-size: 2.2rem;
      line-height: 1.2;
    }
  }

  p {
    font-size: 1.75rem;
    margin: 0.5em 0;

    ${media.desktop`
      margin-left: 1em 0;
    `}
  }

  ${media.desktop`
    margin-left: 4.5rem;
  `}
`;

const arrow = css`
  display: none;
  ${media.desktop`
    display: block;   
    position: absolute; 
  `}
`;

const ArrowOne = styled.div`
  ${arrow};
  left: 71rem;
  width: 20rem;
  top: 8rem;
`;

const ArrowTwo = styled.div`
  ${arrow};
  left: 80rem;
  width: 7rem;
  top: 24rem;
`;

const CustomerSection = () => (
  <Section>
    <Container>
      <h2>
        Who can Shape <span>embedded</span> help?
      </h2>
      <CustomerHolder>
        <Customer>
          <Icon>
            <Clock stroke="#ffcd00" />
          </Icon>
          <Copy>
            <h3>
              {/*<span>Example customer 1</span>*/}
              <div>HR / Time & Attendance Software</div>
            </h3>
            <p>
              Save your customers time and money by embedding Shape’s
              white-label payroll directly into your solution, whilst adding
              addition features for their employees.
            </p>
          </Copy>
        </Customer>
        <Customer>
          <Icon>
            <IdCard stroke="#ffcd00" />
          </Icon>
          <Copy>
            <h3>
              <div>Recruitment & Employer of Record Platforms</div>
            </h3>
            <p>
              Embed payroll and payment features within your technology to
              improve your candidate & employee experience .
            </p>
          </Copy>
        </Customer>

        <Customer>
          <Icon>
            <Box stroke="#ffcd00" />
          </Icon>
          <Copy>
            <h3>
              <div>Vertical / Industry-specific Software</div>
            </h3>
            <p>
              There’s no such thing as “one-size-fits-all”, which is why we
              partner with specialist SaaS platforms to help pay their
              customer’s employees correctly, everytime.
            </p>
          </Copy>
        </Customer>
        <Customer>
          <Icon>
            <Profit stroke="#ffcd00" />
          </Icon>
          <Copy>
            <h3>
              <div>Accountancy-tech</div>
            </h3>
            <p>
              Whether you're looking to deliver a modern client experience or
              focusing on value-added services to grow, Shape’s technology makes
              us the ideal partner.
            </p>
          </Copy>
        </Customer>
        <Customer>
          <Icon>
            <Bank stroke="#ffcd00" />
          </Icon>
          <Copy>
            <h3>
              <div>Banking and Fintech</div>
            </h3>
            <p>
              Shape’s expertise removes payroll complexity, so you can help your
              customers manage and maximize their finances.
            </p>
          </Copy>
        </Customer>
      </CustomerHolder>
      <ArrowOne>
        <Arrow stroke="#ffcd00" />
      </ArrowOne>
      <ArrowTwo>
        <Arrow stroke="#fff" strokeWidth="20" />
      </ArrowTwo>
    </Container>
  </Section>
);

export default CustomerSection;
