import { css } from "styled-components";
import media from "./media";

export const container = css`
  max-width: 71rem;
  margin: 0 auto;
`;

export const sectionPadding = css`
  padding: 3rem 2rem;
  ${media.desktop`
      padding: 5rem 2rem 5rem 2rem;
  `}
`;

export const blackSection = css`
  background: #222;
  color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1209.851' height='976.179' viewBox='0 0 1209.851 976.179'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect width='1209.851' height='976.179' transform='translate(0.089 103.821)' /%3E%3C/clipPath%3E%3C/defs%3E%3Cg transform='translate(-0.089 -103.821)'%3E%3Cg clip-path='url(%23clip-path)'%3E%3Cpath d='M-219.222,1200.288,1025.555,678.16-219.222,156.031Z' fill='%231d1d1b' stroke='%231d1d1b' stroke-linecap='round' stroke-linejoin='round' stroke-width='194.492'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  background-position: bottom left;
  background-repeat: no-repeat;
`;

export const whiteSection = css`
  background: #fff;
  color: #1d1d1b;
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1209.851 902.079'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect width='1209.851' height='902.079' transform='translate(1920.089 1979.083) rotate(-180)' fill='none'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg transform='translate(-710.237 -1077.004)'%3E%3Cg clip-path='url(%23clip-path)'%3E%3Cpath id='Path_4' data-name='Path 4' d='M2139.4,2173.471,894.624,1651.343,2139.4,1129.214Z' fill='%23f6f6f6' stroke='%23f6f6f6' stroke-linecap='round' stroke-linejoin='round' stroke-width='194.492'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-position: bottom right;
  background-repeat: no-repeat;
`;

export const greySection = css`
  background: #ededed;
  color: #1d1d1b;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1209.851 1180.842'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect width='1209.851' height='1180.842' transform='translate(1920.089 4256.462) rotate(-180)' fill='none'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg transform='translate(-710.237 -3075.62)'%3E%3Cg clip-path='url(%23clip-path)'%3E%3Cpath id='Path_5' data-name='Path 5' d='M2139.4,4172.087,894.624,3649.959,2139.4,3127.83Z' fill='%23f6f6f6' stroke='%23f6f6f6' stroke-linecap='round' stroke-linejoin='round' stroke-width='194.492'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
`;

export const darkSection = css`
  background: #222222;
  color: #ffffff;

  h2,
  h3,
  h4 {
    color: #ffcd00;
  }
`;

export const yellowSection = css`
  background: #ffcd00;
  color: #1d1d1b;

  background-position: bottom left;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1209.851 857.61'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect width='1209.851' height='857.61' transform='translate(0.089 5057.312)' fill='none'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg transform='translate(-0.089 -5057.312)'%3E%3Cg clip-path='url(%23clip-path)'%3E%3Cpath d='M-219.222,6100l1244.776-522.128L-219.222,5055.746Z' fill='%23ffd333' stroke='%23ffd333' stroke-linecap='round' stroke-linejoin='round' stroke-width='194.492'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
`;

export const pointyBrackets = css`
  ::before {
    content: "<";
    margin-right: 0.25em;
  }
  ::after {
    content: ">";
    margin-left: 0.25em;
  }
`;

export const pointyAfter = css`
  ::after {
    content: ">";
    margin-left: 0.25em;
  }
`;
