import * as React from "react";
import styled from "styled-components";

import { container } from "../style/mixins";
import media from "../style/media";
import Logo from "./Logo";

const Top = styled.div`
  padding: 4.5rem 2rem 3.25rem 2rem;

  background: #222222;
  color: #ffffff;

  p {
    font-size: 1rem;
  }
`;

const Bottom = styled.div`
  padding: 2rem;

  background: #1d1d1b;
  color: #ffffff;

  text-align: center;

  ${media.tablet`
    text-align: left;
  `}
`;

const Container = styled.div`
  ${container};

  ${media.tablet`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`;

const Links = styled.div`
  margin-bottom: 2rem;

  a {
    margin-right: 1rem;
    text-decoration: none;
    color: #fff;

    :not(:last-child) {
      ::after {
        content: "|";
        margin-left: 1em;
      }
    }
  }
`;

const Footer = () => (
  <footer>
    <Top>
      <Container>
        <div>
          <Logo />
          <p>UK payroll capability embedded in your product</p>
        </div>
      </Container>
    </Top>

    <Bottom>
      <Container>
        <Links>
          <a href="https://www.shapepayroll.com/terms/">Terms</a>
          <a href="https://www.shapepayroll.com/privacy/">Privacy</a>
          <a href="https://www.shapepayroll.com/cookies/">Cookies</a>
        </Links>
        <div>&copy; 2022 Source Shaper Ltd</div>
      </Container>
    </Bottom>
  </footer>
);

export default Footer;
