import * as React from "react";

import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

import { useForm } from "react-hook-form";

const ContactForm = ({ onComplete, onCancel }) => {
  const [isSent, setSent] = React.useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    await fetch(`https://api.shapepayroll.com/api/internal/contact`, {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });

    setSent(true);
  };

  if (isSent) {
    return (
      <>
        <DialogContent>
          <DialogContentText>
            Thank you for your interest in Shape Embedded. Tom or Alex will be
            in touch with you soon.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onComplete}>Close</Button>
        </DialogActions>
      </>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <DialogContentText>
          To get started, or to find out more about Shape Embedded drop us a
          message:
        </DialogContentText>
        <TextField
          label="Your name"
          margin="dense"
          fullWidth
          variant="filled"
          {...register("name", {
            required: true,
          })}
          error={!!errors.email}
          helperText={errors.name ? "Please enter your name" : ""}
        />
        <TextField
          label="Your email address"
          margin="dense"
          fullWidth
          variant="filled"
          {...register("email", {
            required: true,
            pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })}
          error={!!errors.email}
          helperText={
            errors.email
              ? errors.email.type === "pattern"
                ? "Please enter a valid email address"
                : "Please enter your email address"
              : ""
          }
        />
        <TextField
          label="How can we help?"
          margin="dense"
          fullWidth
          multiline
          rows={6}
          variant="filled"
          {...register("message")}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="submit">Send</Button>
      </DialogActions>
    </form>
  );
};

export default ContactForm;
